import {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from 'react';
import { EmotionJSX } from '@emotion/react/types/jsx-namespace';
import { ModalWindowComponent } from '../components/common/Modal';
import { ModalState, initialModalState } from '../models/modal';

export const ModalContext = createContext<{
  modalState: ModalState;
  setModalState: Dispatch<SetStateAction<ModalState>>;
  Modal: EmotionJSX.Element | null;
} | null>(null);

function ModalComponent(
  modalState: ModalState,
  setModalState: Dispatch<SetStateAction<ModalState>>
) {
  return modalState.show ? (
    <ModalWindowComponent
      outsideCloseCallback={modalState.outsideCloseCallback}
      content={modalState.content}
      options={modalState.options}
      setModalState={setModalState}
      enableOutsideClick={modalState.enableOutsideClick}
    />
  ) : null;
}

export function ModalProvider({ children }: { children: React.ReactNode }) {
  const [modalState, setModalState] = useState<ModalState>(initialModalState);
  // eslint-disable-next-line react/no-unstable-nested-components
  const contextValue = useMemo(
    () => ({
      modalState,
      setModalState,
      Modal: () => ModalComponent(modalState, setModalState),
    }),
    [modalState]
  );
  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <ModalContext.Provider value={contextValue}>
      {children}
    </ModalContext.Provider>
  );
}

export function useModal() {
  return useContext(ModalContext);
}
