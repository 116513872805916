const colors = {
  primary: '#ff4a2e',
  black: '#000',
  red: '#f44336',
  buttonRed: '#fa4530',
  gradient: '#de2839',
  secondary: '#f5564a',
  white: '#ffffff',
  text: '#282828',
  textWhite: '#f8f8f8',
  textSecondary: '#333333',
  grayStrong: '#bfc1c3',
  bgFooter: '#424242',
};

const constants = {
  padding: '8px',
  boxShadow: '0 4px 10px 0 rgba(0, 0, 0, 0.1)',
  borderRadius: '4px',
};

const mixins = {
  row: (margin = constants.padding) => ({
    display: 'flex',
    flexWrap: 'wrap',
    marginRight: `-${margin}`,
    marginLeft: `-${margin}`,
  }),
  col: (col: number, padding = constants.padding) => ({
    position: 'relative',
    width: '100%',
    minHeight: '1px',
    flex: `0 0 ${100 / col}%`,
    maxWidth: `${100 / col}%`,
    paddingRight: padding,
    paddingLeft: padding,
  }),
  flex: () => ({ display: 'flex', flexWrap: 'wrap', width: '100%' }),
  absoluteInsideBox: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    overflow: 'hidden',
  },
  responsiveBox: (height: string) => ({
    position: 'relative',
    width: '100%',
    '&::before': {
      content: "''",
      display: 'block',
      paddingTop: height,
    },
  }),
  responsiveBoxInside: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    overflow: 'hidden',
  },
  imageInsideBox: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    maxWidth: '100%',
  },
  fonts: {
    fontMedium: {
      fontFamily: 'Golos',
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '16px',
      letterSpacing: '0.01em',
    },
    fontButton: {
      fontFamily: 'Golos',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '16px',
      letterSpacing: '0em',
    },
    fontBody: {
      fontFamily: 'Golos',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '20px',
      letterSpacing: '0em',
      strong: {
        fontWeight: 800,
      },
    },
    golos: {
      fontFamily: 'Golos',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '22px',
    },
  },
};

export const globalStyles = ({ theme }: { theme: ThemeType }) => ({
  // TODO работает?;
  body: {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: 1.4,
    color: theme.colors.text,
  },
});

export const theme = {
  colors,
  mixins,
  constants,
};

export type ThemeType = typeof theme;
