import { DateTime } from 'luxon';

export const DEVICE_SCREENS = {
  sm: 575,
  md: 767,
  lg: 992,
  xl: 1200,
  xxl: 1330,
};

export const DEVICE_SCREENS_MIN_STRING = {
  sm: `@media(min-width: ${DEVICE_SCREENS.sm}px)`,
  md: `@media(min-width: ${DEVICE_SCREENS.md}px)`,
  lg: `@media(min-width: ${DEVICE_SCREENS.lg}px)`,
  xl: `@media(min-width: ${DEVICE_SCREENS.xl}px)`,
  xxl: `@media(min-width: ${DEVICE_SCREENS.xxl}px)`,
};
export const DEVICE_SCREENS_MAX_STRING = {
  sm: `@media(max-width: ${DEVICE_SCREENS.sm}px)`,
  md: `@media(max-width: ${DEVICE_SCREENS.md}px)`,
  lg: `@media(max-width: ${DEVICE_SCREENS.lg}px)`,
  xl: `@media(max-width: ${DEVICE_SCREENS.xl}px)`,
  xxl: `@media(max-width: ${DEVICE_SCREENS.xxl}px)`,
};

export const DEFAULT_SEO_TITLE =
  'Горбилет — сервис по продаже билетов со скидкой';

export const DEFAULT_SEO_DESCRIPTION =
  'Бесплатно забронируем билеты на балет, мюзиклы, спектакли, концерты, музеи, экскурсии со скидкой до 70%';

export const catalogOrderingArr = [
  {
    title: 'По популярности',
    value: '-views_per_3days',
  },
  {
    title: 'По новинкам',
    value: '-activated_at',
  },
  {
    title: 'По возрастанию цены',
    value: 'filter_price',
  },
  {
    title: 'По убыванию цены',
    value: '-filter_price',
  },
  {
    title: 'По размеру скидки',
    value: '-discount',
  },
  {
    title: 'По дате',
    value: 'date',
  },
];

export const catalogOrderingApiToUrl = {
  '-views_per_3days': 'popular',
  '-activated_at': 'new',
  filter_price: 'low',
  '-filter_price': 'high',
  '-discount': 'profit',
  date: 'date',
  '': 'alphabet',
};

export const catalogOrderingUrlToApi = {
  popular: '-views_per_3days',
  new: '-activated_at',
  low: 'filter_price',
  high: '-filter_price',
  profit: '-discount',
  date: 'date',
  alphabet: '',
};

export const filterPriceArr = [
  {
    id: 0,
    title: 'Бесплатно',
    price1: 0,
    price2: 0,
  },
  {
    id: 1,
    title: 'От 0 до 1000 ₽',
    price1: 1,
    price2: 999,
  },
  {
    id: 2,
    title: 'От 1000 до 3000 ₽',
    price1: 1000,
    price2: 2999,
  },
  {
    id: 3,
    title: 'От 3000 ₽',
    price1: 3000,
    price2: 1000 * 1000,
  },
];
export const filterDates: {
  [key: number]: [string, string];
} = {
  0: [
    DateTime.now().toFormat('yyyy-LL-dd'),
    DateTime.now().toFormat('yyyy-LL-dd'),
  ],
  1: [
    DateTime.now().plus({ days: 1 }).toFormat('yyyy-LL-dd'),
    DateTime.now().plus({ days: 1 }).toFormat('yyyy-LL-dd'),
  ],
  2: [
    DateTime.local().startOf('week').toFormat('yyyy-LL-dd'),
    DateTime.local().startOf('week').plus({ days: 4 }).toFormat('yyyy-LL-dd'),
  ],
  3: [
    DateTime.local().startOf('week').plus({ days: 5 }).toFormat('yyyy-LL-dd'),
    DateTime.local().startOf('week').plus({ days: 6 }).toFormat('yyyy-LL-dd'),
  ],
  4: [
    DateTime.local().startOf('week').plus({ days: 7 }).toFormat('yyyy-LL-dd'),
    DateTime.local().startOf('week').plus({ days: 13 }).toFormat('yyyy-LL-dd'),
  ],
};
export const filterDateArr = [
  {
    id: 0,
    title: 'Сегодня',
  },
  {
    id: 1,
    title: 'Завтра',
  },
  {
    id: 2,
    title: 'Будние',
  },
  {
    id: 3,
    title: 'Выходные',
  },
  {
    id: 4,
    title: 'На следующей неделе',
  },
];

export const abcRuArray = [
  'А',
  'Б',
  'В',
  'Г',
  'Д',
  'Е',
  'Ж',
  'З',
  'И',
  'К',
  'Л',
  'М',
  'Н',
  'О',
  'П',
  'Р',
  'С',
  'Т',
  'У',
  'Ф',
  'Х',
  'Ц',
  'Ч',
  'Ш',
  'Щ',
  'Э',
  'Ю',
  'Я',
];

export const DEFAULT_REVALIDATION_TIME = 60; // in seconds

export const appleItunesAppId = '1435803625';
