import { ReactNode } from 'react';

export interface ModalOptions {
  width?: string | number;
  background?: string;
  boxShadow?: string;
}

export type ModalState = {
  show: boolean;
  content: ReactNode | string;
  options?: ModalOptions;
  outsideCloseCallback?: () => void;
  enableOutsideClick?: boolean;
};

export const initialModalState: ModalState = {
  show: false,
  content: '',
};
