import React, {
  createContext,
  useState,
  useMemo,
  Dispatch,
  SetStateAction,
  useContext,
} from 'react';
import { HistoryLastPage } from '../../models/history';

const HistoryContext = createContext<{
  lastPage: null | HistoryLastPage;
  setLastPage: Dispatch<SetStateAction<HistoryLastPage | null>>;
}>({ lastPage: null, setLastPage: () => {} });

export function HistoryProvider({ children }: { children: React.ReactNode }) {
  const [lastPage, setLastPage] = useState<HistoryLastPage | null>(null);

  const contextValue = useMemo(
    () => ({
      lastPage,
      setLastPage,
    }),
    [lastPage]
  );

  return (
    <HistoryContext.Provider value={contextValue}>
      {children}
    </HistoryContext.Provider>
  );
}

export function useHistory() {
  return useContext(HistoryContext);
}
