import {
  css,
  Global,
  CacheProvider as EmotionCacheProvider,
  ThemeProvider as EmotionThemeProvider,
} from '@emotion/react';
import { theme, globalStyles } from './theme';
import emotionCache from './cache';

export function ThemeProvider({ children }: { children: React.ReactNode }) {
  return (
    <EmotionThemeProvider theme={theme}>
      <Global styles={css(globalStyles({ theme }))} />
      {children}
    </EmotionThemeProvider>
  );
}

export function CacheProvider({ children }: { children: React.ReactNode }) {
  return (
    <EmotionCacheProvider value={emotionCache}>{children}</EmotionCacheProvider>
  );
}
