import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { EmotionJSX } from '@emotion/react/types/jsx-namespace';
import MainLoader from '../../components/loader/MainLoader';

export function LoadingSpinner({ loading }: { loading: boolean }) {
  return loading ? <MainLoader /> : null;
}

function LoadingComponent({ loading: externalLoading }: { loading?: boolean }) {
  const [loading, setLoading] = useState(Boolean(externalLoading));

  useEffect(() => {
    if (typeof externalLoading !== 'undefined') {
      setLoading(externalLoading);
    }
  }, [externalLoading]);

  return <LoadingSpinner loading={loading} />;
}

const LoadingContext = createContext<{
  isLoading: boolean;
  updateLoading: (newStatus: boolean) => void;
  LoaderComponent: () => EmotionJSX.Element | null;
}>({
  isLoading: false,
  updateLoading: () => {},
  LoaderComponent: () => LoadingComponent({ loading: false }),
});

export function LoadingProvider({ children }: { children: React.ReactNode }) {
  const [isLoading, setIsLoading] = useState(false);
  function updateLoading(newStatus: boolean) {
    setIsLoading(newStatus);
  }
  const contextValue = useMemo(
    () => ({
      isLoading,
      updateLoading,
      LoaderComponent: () => LoadingComponent({ loading: isLoading }),
    }),
    [isLoading]
  );

  return (
    <LoadingContext.Provider value={contextValue}>
      {children}
    </LoadingContext.Provider>
  );
}

export function useLoading() {
  return useContext(LoadingContext);
}
