// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import preview from 'html-preview';
import { DateTime } from 'luxon';
import wordDeclension from './word-declension';

// TODO типизировать это;
export const nestComponents = (components: any) => (props: any) =>
  components.reduce(
    (children: any, Current: any) => <Current {...props}>{children}</Current>,
    props.children
  );

export function splitPriceByThree(price: number | string): string {
  const [integer, float] = String(price).split('.');
  let resultPrice = String(integer)
    .split('')
    .reverse()
    .join('')
    .match(/.{1,3}/g)!
    .join(' ')
    .split('')
    .reverse()
    .join('');
  if (float) {
    resultPrice = `${resultPrice}.${float}`;
  }
  return resultPrice;
}

export function getBenefit(
  originPrice: number | string,
  filterPrice: number | string
): number {
  return Number(originPrice) - Number(filterPrice);
}

export const getTextPreview = (text: any, limit: any) => {
  const cleanText = text.replace(/&nbsp;/g, ' ');
  // the parser always removes the parent tag, so an estimated number of characters (e.g. div) are added
  if (cleanText.length > preview(cleanText, limit).length + 11) {
    const cutText = preview(cleanText, limit);
    let isStartHtmlTag = false;
    for (let i = cutText.length - 1; i !== 0; i -= 1) {
      if (
        cutText[i] !== '>' &&
        cutText[i] !== ' ' &&
        cutText[i] !== '.' &&
        !isStartHtmlTag
      ) {
        return `${cutText.substr(0, i)}...${cutText.substr(
          i + 1,
          cutText.length
        )}`;
      }
      if (cutText[i] === '>' && !isStartHtmlTag) {
        isStartHtmlTag = true;
      } else if (cutText[i] === '<' && isStartHtmlTag) {
        isStartHtmlTag = false;
      }
    }
    return `${preview(cleanText, limit)}...`;
  }
  return cleanText;
};

export const handlerQueryDate = (queryDate: any) => {
  const queryData: any = { dateFilterId: null, date_from: [], date_to: [] };
  if (queryDate.length === 2) {
    switch (true) {
      // today
      // id from filterPriceArr
      case queryDate[0] === DateTime.now().toFormat('dd.LL.yyyy') &&
        queryDate[1] === DateTime.now().toFormat('dd.LL.yyyy'): {
        queryData.dateFilterId = 0;
        queryData.date_from = DateTime.now().toFormat('yyyy-LL-dd');
        queryData.date_to = DateTime.now().toFormat('yyyy-LL-dd');
        break;
      }
      // tomorrow
      case queryDate[0] ===
        DateTime.now().plus({ days: 1 }).toFormat('dd.LL.yyyy') &&
        queryDate[1] ===
          DateTime.now().plus({ days: 1 }).toFormat('dd.LL.yyyy'): {
        queryData.dateFilterId = 1;
        queryData.date_from = DateTime.now()
          .plus({ days: 1 })
          .toFormat('yyyy-LL-dd');
        queryData.date_to = DateTime.now()
          .plus({ days: 1 })
          .toFormat('yyyy-LL-dd');
        break;
      }
      // weekdays
      case queryDate[0] ===
        DateTime.local().startOf('week').toFormat('dd.LL.yyyy') &&
        queryDate[1] ===
          DateTime.local()
            .startOf('week')
            .plus({ days: 4 })
            .toFormat('dd.LL.yyyy'): {
        queryData.dateFilterId = 2;
        queryData.date_from = DateTime.local()
          .startOf('week')
          .toFormat('yyyy-LL-dd');
        queryData.date_to = DateTime.local()
          .startOf('week')
          .plus({ days: 4 })
          .toFormat('yyyy-LL-dd');
        break;
      }
      // weekend
      case queryDate[0] ===
        DateTime.local()
          .startOf('week')
          .plus({ days: 5 })
          .toFormat('dd.LL.yyyy') &&
        queryDate[1] ===
          DateTime.local()
            .startOf('week')
            .plus({ days: 6 })
            .toFormat('dd.LL.yyyy'): {
        queryData.dateFilterId = 3;
        queryData.date_from = DateTime.local()
          .startOf('week')
          .plus({ days: 5 })
          .toFormat('yyyy-LL-dd');
        queryData.date_to = DateTime.local()
          .startOf('week')
          .plus({ days: 6 })
          .toFormat('yyyy-LL-dd');
        break;
      }
      // on next week
      case queryDate[0] ===
        DateTime.local()
          .startOf('week')
          .plus({ days: 7 })
          .toFormat('dd.LL.yyyy') &&
        queryDate[1] ===
          DateTime.local()
            .startOf('week')
            .plus({ days: 13 })
            .toFormat('dd.LL.yyyy'): {
        queryData.dateFilterId = 4;
        queryData.date_from = DateTime.local()
          .startOf('week')
          .plus({ days: 7 })
          .toFormat('yyyy-LL-dd');
        queryData.date_to = DateTime.local()
          .startOf('week')
          .plus({ days: 13 })
          .toFormat('yyyy-LL-dd');
        break;
      }
      default:
        queryData.dateFilterId = null;
        queryData.date_from = DateTime.fromFormat(
          queryDate[0],
          'dd.LL.yyyy'
        ).toFormat('yyyy-LL-dd');
        queryData.date_to = DateTime.fromFormat(
          queryDate[1],
          'dd.LL.yyyy'
        ).toFormat('yyyy-LL-dd');
    }
  }
  return queryData;
};

export const getEventTextFromCount = (count: number) => {
  if (count === 1) {
    return 'событие';
  }
  if (count > 1 && count < 5) {
    return 'события';
  }
  return 'событий';
};

export function getParamsFromSearch(search: string) {
  const urlParams = new URLSearchParams(search);
  return Object.fromEntries(urlParams);
}

export const getTitleForTimer = (timerType: string) => {
  if (timerType === 'untilEnd') {
    return 'До конца акции';
  }
  if (timerType === 'untilStart') {
    return 'До начала акции';
  }
  if (timerType === 'untilNearest' || timerType === 'manual') {
    return 'До события';
  }
  return null;
};

export const formatTimer = (
  days: number,
  hours: number,
  minutes: number,
  seconds: number
) => {
  let strTimer = '';
  if (days >= 5) {
    strTimer = `${days} дней `;
  }
  if (days === 1) {
    strTimer = '1 день ';
  }
  if (days > 1 && days < 5) {
    strTimer = `${days} дня `;
  }
  if (hours < 10) {
    strTimer += `0${hours}:`;
  } else {
    strTimer += `${hours}:`;
  }
  if (minutes < 10) {
    strTimer += `0${minutes}:`;
  } else {
    strTimer += `${minutes}:`;
  }
  if (seconds < 10) {
    strTimer += `0${seconds}`;
  } else {
    strTimer += `${seconds}`;
  }
  return strTimer;
};

export const formatTimerEvent = (
  days: number,
  hours: number,
  minutes: number,
  seconds: number
) => {
  const daySubstr = wordDeclension(days, 'день', 'дня', 'дней');
  const hoursSubstr = wordDeclension(hours, 'час', 'часа', 'часов');
  const minutesSubstr = wordDeclension(minutes, 'минута', 'минуты', 'минут');
  const secondsSubstr = wordDeclension(seconds, 'секунда', 'секунды', 'секунд');

  let resultText = '';

  if (days) {
    resultText = `${days} ${daySubstr}`;
    if (hours) {
      resultText += ` ${hours} ${hoursSubstr}`;
    }
  } else if (hours) {
    resultText = `${hours} ${hoursSubstr}`;
    if (minutes) {
      resultText += ` ${minutes} ${minutesSubstr}`;
    }
  } else if (minutes) {
    resultText = `${minutes} ${minutesSubstr}`;
    if (seconds) {
      resultText += ` ${seconds} ${secondsSubstr}`;
    }
  } else if (seconds) {
    resultText = `${seconds} ${secondsSubstr}`;
  } else {
    resultText = '';
  }

  return resultText;
};

export interface Countdown {
  date?: number;
  text?: string;
  progress?: string;
  action_text?: string;
}

export const countdownInit = (
  dateList: any,
  countdownType: string,
  clb: (countdown: Countdown) => void
) => {
  if (
    !dateList ||
    !dateList.length ||
    countdownType === 'unset' ||
    !countdownType
  )
    return;

  const countdown: Countdown = {};

  countdown.date = (
    {
      manual: () => dateList[0].valueOf() - Date.now(),
      untilStart: () => dateList[0].valueOf() - Date.now(),
      untilEnd: () => dateList[dateList.length - 1].valueOf() - Date.now(),
      untilNearest: () => {
        const futureDates = dateList.filter(
          (date: any) => date.valueOf() - Date.now() > 0
        );
        if (futureDates.length) {
          return futureDates[0].valueOf() - Date.now();
        }
        return null;
      },
    } as { [key: string]: (() => number) | (() => number | undefined) }
  )[countdownType]();

  // счетчик не нужен если больше 7 суток
  const msInWeek = 7 * 24 * 60 * 60 * 1000;
  if (!countdown.date || countdown.date > msInWeek || countdown.date < 0) {
    // eslint-disable-next-line consistent-return
    return false;
  }

  const countdownTick = () => {
    if (!countdown.date || countdown.date > msInWeek || countdown.date < 0) {
      return;
    }

    countdown.date -= 1000;
    let t = countdown.date / 1000;

    countdown.progress = (
      ((msInWeek - countdown.date) / msInWeek) *
      100
    ).toFixed(2);

    const days = Math.floor(t / 86400);
    t -= days * 86400;
    const hours = Math.floor(t / 3600) % 24;
    t -= hours * 3600;
    const minutes = Math.floor(t / 60) % 60;
    t -= minutes * 60;
    const seconds = Math.floor(t % 60);

    const [hoursText, minutesText, secondsText] = [hours, minutes, seconds].map(
      (dateNum) => dateNum.toString().padStart(2, '0')
    );

    const daySubstr = wordDeclension(days, 'день', 'дня', 'дней');
    const hoursSubstr = wordDeclension(hours, 'час', 'часа', 'часов');
    const minutesSubstr = wordDeclension(minutes, 'минута', 'минуты', 'минут');
    const secondsSubstr = wordDeclension(
      seconds,
      'секунда',
      'секунды',
      'секунд'
    );

    if (days) {
      countdown.action_text = `${days} ${daySubstr}`;
      if (hours) {
        countdown.action_text += ` ${hours} ${hoursSubstr}`;
      }
    } else if (hours) {
      countdown.action_text = `${hours} ${hoursSubstr}`;
      if (minutes) {
        countdown.action_text += ` ${minutes} ${minutesSubstr}`;
      }
    } else if (minutes) {
      countdown.action_text = `${minutes} ${minutesSubstr}`;
      if (seconds) {
        countdown.action_text += ` ${seconds} ${secondsSubstr}`;
      }
    } else if (seconds) {
      countdown.action_text = `${seconds} ${secondsSubstr}`;
    } else {
      countdown.action_text = '';
    }

    countdown.text =
      (days ? `${days} ${daySubstr} ` : '') +
      [hoursText, minutesText, secondsText].join(':');

    clb(countdown);
  };

  countdownTick();
};

export const revealDate = (
  dateArr: {
    interval: string;
    time: string[];
  }[]
) => {
  if (!dateArr) return [];
  return dateArr
    .map((date) => {
      if (date.interval.indexOf('-') > -1) {
        const [start, finish] = date.interval.split(' - ');
        const daysDiff =
          (new Date(finish).getTime() - new Date(start).getTime()) /
          (24 * 60 * 60 * 1000);
        return new Array(daysDiff + 1)
          .fill(0)
          .map(() =>
            Array((date.time && date.time.length) || 1)
              .fill(0)
              .map((_item, subIndex) => {
                const time = date.time[subIndex];
                const isInterval = !!time && !!time.length;

                return isInterval
                  ? DateTime.fromFormat(
                      `${start} ${date.time[subIndex]}`,
                      'yyyy-MM-dd HH:mm:ss'
                    )
                  : DateTime.fromFormat(`${start}`, 'yyyy-MM-dd');
              })
          )
          .reduce((list, x) => list.concat(x), []);
      }
      return [];
    })
    .reduce((list: any, x: any) => list.concat(x), [])
    .filter(
      (i: DateTime) =>
        Math.round(i.valueOf() / 1000 - Date.now() / 1000) >
        (i.toFormat('HH:mm') === '00:00' ? -24 * 60 * 60 : -0)
    )
    .sort((a: DateTime, b: DateTime) => a.valueOf() - b.valueOf());
};

export const setCacheControlHeader = (context: {
  res: { setHeader: (a: string, b: string) => void };
}) => {
  context.res.setHeader(
    'Cache-Control',
    'private, s-maxage=10, stale-while-revalidate=59'
  );
  context.res.setHeader('Vary', 'Cookie');
};

export default function objectToGetParams(object: {
  [key: string]: string | number | undefined | null;
}) {
  const params = Object.entries(object)
    .filter(([, value]) => value !== undefined && value !== null)
    .map(
      ([key, value]) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`
    );

  return params.length > 0 ? `?${params.join('&')}` : '';
}
