import { createContext, useContext, useEffect, useMemo } from 'react';
import { useRouter } from 'next/router';
import { Events, Event } from './index.model';

const SeoAnalyticsContext = createContext<{
  dispatch?: (event: string | Event) => boolean;
  saveCall?: (driverName: 'ga' | 'ym', methodName: any, params: any) => boolean;
}>({});

export function SeoAnalyticsProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const router = useRouter();
  const events: Events = {
    clickAppleApp: { ym: ['click.ios'], ga: ['app', 'clickIos', 'appIos'] },
    clickGooglePlayApp: {
      ym: ['click.and'],
      ga: ['app', 'clickAnd', 'appAnd'],
    },
  };

  function saveCall(driverName: 'ga' | 'ym', methodName: any, params: any) {
    try {
      if (typeof window === 'undefined') {
        return false;
      }
      const globalDriver =
        window[
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          {
            ga: 'gtag',
            ym: 'yaCounter37790595',
          }[driverName]
        ];
      if (typeof globalDriver === 'undefined') {
        return false;
      }
      if (methodName) {
        globalDriver[methodName](...params);
      } else {
        globalDriver(...params);
      }
      return true;
    } catch (err) {
      console.error(err);
    } finally {
      // eslint-disable-next-line no-unsafe-finally
      return false;
    }
  }
  function ymSend(goalName?: string) {
    return saveCall('ym', 'reachGoal', [goalName]);
  }
  function gaSend(
    eventCategory?: string,
    eventAction?: string,
    eventLabel?: string
  ) {
    return saveCall('ga', null, [
      'event',
      eventAction,
      {
        event_category: eventCategory,
        event_label: eventLabel,
      },
    ]);
  }

  function dispatch(event: string | Event) {
    const drivers = {
      ym: 'ymSend',
      ga: 'gaSend',
    };

    const methods = {
      ymSend,
      gaSend,
    };
    const eventDictionary = typeof event === 'string' ? events[event] : event;
    if (!eventDictionary) {
      return false;
    }
    // eslint-disable-next-line no-restricted-syntax
    for (const [driverName, driverFnName] of Object.entries(drivers)) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const payload = eventDictionary[driverName];
      if (payload) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        methods[driverFnName](...payload);
      }
    }
    return true;
  }

  useEffect(() => {
    const handleComplete = (url: string) =>
      url !== router.asPath && saveCall('ym', 'hit', [url]);

    router.events.on('routeChangeComplete', handleComplete);

    return () => {
      router.events.off('routeChangeComplete', handleComplete);
    };
  });

  const contextValue = useMemo(() => ({ dispatch, saveCall }), []);
  return (
    <SeoAnalyticsContext.Provider value={contextValue}>
      {children}
    </SeoAnalyticsContext.Provider>
  );
}

export function useSeoAnalytics() {
  return useContext(SeoAnalyticsContext);
}
