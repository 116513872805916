import { Favorite } from '../../models';
import { FavoriteKinds } from '../../models/favorite';

export const FAVORITES_ACTIONS: {
  ADD: 'ADD';
  DELETE: 'DELETE';
} = {
  ADD: 'ADD',
  DELETE: 'DELETE',
};

export type UpdateFavoritesSignature = (
  itemId: number | string,
  kind: keyof typeof FavoriteKinds
) => Promise<void>;

export function getFavoriteKind(item: Favorite) {
  if (item.event) {
    return FavoriteKinds.event;
  }
  if (item.person) {
    return FavoriteKinds.person;
  }
  if (item.place) {
    return FavoriteKinds.place;
  }
  if (item.material) {
    return FavoriteKinds.material;
  }
  return null;
}

export interface FavoriteModel {
  id: string;
  favId: string;
  kind: string;
}
