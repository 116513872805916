import styled from '@emotion/styled';

export const Spinner = styled.div`
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  height: 50px;
  width: 50px;
  margin: -25px 0 0 -25px;
  border: 4px rgba(244, 67, 54, 0.25) solid;
  border-top: 4px #f44336 solid;
  border-radius: 50%;
  animation: spin 1s infinite linear;

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
`;

export const notShowPreloderUrlPath: string[] = [
  'catalog',
  'person-catalog',
  'favorite',
  'place-catalog',
  'search',
];

function MainLoader() {
  return (
    <div
      css={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 20,
      }}
    >
      <Spinner />
    </div>
  );
}

export default MainLoader;
