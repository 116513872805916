import { useContext, createContext, useState, useMemo, useEffect } from 'react';
import { v4 as generateUUID } from 'uuid';
import { http } from '../lib/api/http';
import { V2, V3 } from '../lib/api/urls';
import { User } from '../models/profile';

type LoginSignature = (email: string, password: string) => Promise<boolean>;
type LogoutSignature = () => Promise<boolean>;
type LoginVKSignature = () => Promise<void>;

const AuthorizationContext = createContext<{
  isReady: boolean;
  isAuthenticated: boolean | null;
  login?: LoginSignature;
  logout?: LogoutSignature;
  getUser: () => User | null;
  onLoginVK?: LoginVKSignature;
  user: User | null;
}>({ isReady: false, isAuthenticated: false, getUser: () => null, user: null });

export function AuthorizationProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [user, setUser] = useState<User | null>(null);
  const [isReady, setIsReady] = useState<boolean>(false);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const getUser = () => user;

  const fetchUser = async () => {
    const userData: User = await http.get(V3.user, null, null, {
      contentType: 'json',
      withTokenRequest: true,
    });
    setUser(userData);
  };
  const onLoginVK = async () => {
    const localStorageUser = localStorage.getItem('current-user');
    const parsedUser = localStorageUser ? JSON.parse(localStorageUser) : null;
    if (parsedUser && parsedUser.token) {
      await fetchUser();
      setIsAuthenticated(true);
      setIsReady(true);
    }
  };

  const login: (
    username: string,
    password: string,
    isAnonUser?: boolean
  ) => Promise<any> = async (username, password, isAnonUser) =>
    http
      .post(V2.token, { username, password })
      .then((response) => {
        if (response.status === 401) {
          throw response.data;
        }
        if (response.status === 200) {
          const localStorageUser = localStorage.getItem('current-user');
          const parsedUser = localStorageUser
            ? JSON.parse(localStorageUser)
            : null;
          localStorage.setItem(
            'current-user',
            JSON.stringify({
              [isAnonUser ? 'uuid' : 'email']: username,
              token: response.data.access,
              refresh: response.data.refresh,
            })
          );
          if (parsedUser && parsedUser.uuid && !isAnonUser) {
            http.post(V2.userMerge, { anon_user_uuid: parsedUser.uuid }, null, {
              contentType: 'json',
              withTokenRequest: true,
            });
          }
          fetchUser().then(() => {
            setIsAuthenticated(!isAnonUser);
            setIsReady(true);
          });
        }
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  const createAnonUser = () => {
    const uuid = generateUUID();
    http.post(V3.register, { anon_uuid: uuid }).then((response) => {
      login(uuid, response.data.password, true).then(() => {
        setIsAuthenticated(false);
        setIsReady(true);
      });
    });
  };
  const logout: LogoutSignature = () => {
    localStorage.removeItem('current-user');
    setUser(null);
    createAnonUser();
    return Promise.resolve(true);
  };
  useEffect(() => {
    const localStorageUser = localStorage.getItem('current-user');
    const parsedUser = localStorageUser ? JSON.parse(localStorageUser) : null;
    if (parsedUser?.token) {
      fetchUser()
        .then(() => {
          setIsAuthenticated(!parsedUser.uuid);
          setIsReady(true);
        })
        .catch((err) => {
          if (err.statusCode === 401) {
            http
              .post(V2.tokenRefresh, {
                refresh: parsedUser.refresh,
              })
              .then((response) => {
                if (response.status === 401) {
                  logout();
                }
                if (response.status === 200) {
                  localStorage.setItem(
                    'current-user',
                    JSON.stringify({
                      [parsedUser.email ? 'email' : 'uuid']: parsedUser.email
                        ? parsedUser.email
                        : parsedUser.uuid,
                      token: response.data.access,
                      refresh: response.data.refresh,
                    })
                  );
                  fetchUser()
                    .then(() => {
                      setIsAuthenticated(true);
                      setIsReady(true);
                    })
                    .catch(() => {
                      localStorage.removeItem('current-user');
                      createAnonUser();
                    });
                }
                return response.data;
              })
              .catch((error) => {
                throw error;
              });
          }
        });
    } else {
      createAnonUser();
    }
  }, []);
  const contextValue = useMemo(
    () => ({
      isReady,
      isAuthenticated,
      login,
      logout,
      getUser,
      onLoginVK,
      user,
    }),
    [getUser]
  );
  return (
    <AuthorizationContext.Provider value={contextValue}>
      {children}
    </AuthorizationContext.Provider>
  );
}

export function useAuthorization() {
  return useContext(AuthorizationContext);
}
