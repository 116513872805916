import '../styles/styles.css';
import '../public/fonts/montserrat/index.css';
import '../public/fonts/golos/index.css';
import type { AppProps } from 'next/app';
import { nestComponents } from '../utils';
import {
  AuthorizationProvider,
  ModalProvider,
  ThemeProvider,
  FavoriteProvider,
  LoadingProvider,
  HistoryProvider,
  SeoAnalyticsProvider,
} from '../providers';

const providers = [
  FavoriteProvider,
  AuthorizationProvider,
  ThemeProvider,
  ModalProvider,
  LoadingProvider,
  HistoryProvider,
  SeoAnalyticsProvider,
];
const Providers = nestComponents(providers);

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <Providers>
      <Component {...pageProps} />
    </Providers>
  );
}

export default MyApp;
