import { API_URL } from './apiUrl';

const baseUrlV2 = `${API_URL}/v2`;
const baseUrlV3 = `${API_URL}/v3`;
const baseUrlV4 = `${API_URL}/v4`;

export const V2 = {
  places: [baseUrlV2, 'places', ''].join('/'),
  place: (id: number) => [baseUrlV2, 'places', id, ''].join('/'),
  placeBySlug: (slug: string) => [baseUrlV2, 'places', slug, ''].join('/'),
  recommendations: [baseUrlV2, 'recommendations', ''].join('/'),
  blogCategories: [baseUrlV2, 'blog-categories', ''].join('/'),
  materials: [baseUrlV2, 'materials', ''].join('/'),
  material: (id: number) => [baseUrlV2, 'materials', id, ''].join('/'),
  pages: [baseUrlV2, 'pages', ''].join('/'),
  socials: [baseUrlV2, 'socials', ''].join('/'),
  contacts: [baseUrlV2, 'contacts', ''].join('/'),
  faq: [baseUrlV2, 'faq', ''].join('/'),
  eventViews: [baseUrlV2, 'event-views', ''].join('/'),
  promos: [baseUrlV2, 'promos', ''].join('/'),
  persons: [baseUrlV2, 'persons', ''].join('/'),
  person: (id: number) => [baseUrlV2, 'persons', id, ''].join('/'),
  personBySlug: (slug: string) => [baseUrlV2, 'persons', slug, ''].join('/'),
  specializations: [baseUrlV2, 'specializations', ''].join('/'),
  subscriptions: [baseUrlV2, 'subscriptions', ''].join('/'),
  baseUrl: [baseUrlV2, ''].join('/'),
  token: [baseUrlV2, 'token', ''].join('/'),
  tokenRefresh: [baseUrlV2, 'token/refresh', ''].join('/'),
  exchangeTokenVK: [baseUrlV2, 'vk/exchange-token', ''].join('/'),
  config: [baseUrlV2, 'config', ''].join('/'),
  recovery: [baseUrlV2, 'user', 'password', 'restore', ''].join('/'),
  userMerge: [baseUrlV2, 'user-merge', ''].join('/'),
  comments: [baseUrlV2, 'comments', ''].join('/'),
  invite: [baseUrlV2, 'invites', ''].join('/'),
  promo: [baseUrlV2, 'promo-code-activations', ''].join('/'),
  changePassword: [baseUrlV2, 'user', 'password', 'change', ''].join('/'),
  smartbiletCheck: [baseUrlV2, 'smartbilet', 'check', ''].join('/'),
  acceptInvite: (uuid: string) => [baseUrlV2, 'invites', uuid, ''].join('/'),
};
export const V3 = {
  cities: [baseUrlV3, 'cities', ''].join('/'),
  categories: [baseUrlV3, 'categories', ''].join('/'),
  adpCategories: (cityId: number) =>
    [baseUrlV3, 'cities', cityId, 'adp-categories', ''].join('/'),
  compilations: {
    root: [baseUrlV3, 'compilations', ''].join('/'),
    slug: (slug: string) => [baseUrlV3, 'compilations', slug, ''].join('/'),
  },
  events: [baseUrlV3, 'events', ''].join('/'),
  eventsId: (id: number) => [baseUrlV3, 'events', id, ''].join('/'),
  eventsSlug: (slug: string) => [baseUrlV3, 'events', slug, ''].join('/'),
  favorites: [baseUrlV3, 'favorites', ''].join('/'),
  favoriteItem: (favId: number | string) =>
    [baseUrlV3, 'favorites', favId, ''].join('/'),
  baseUrl: [baseUrlV3, ''].join('/'),
  user: [baseUrlV3, 'user', 'profile', ''].join('/'),
  changeProfile: [baseUrlV3, 'user', 'profile', ''].join('/'),
  register: [baseUrlV3, 'user-create', ''].join('/'),
  recovery: [baseUrlV3, 'user', 'password', 'restore', ''].join('/'),
  recoveryPassword: (id: string) =>
    [baseUrlV3, 'user', 'password', 'restore', id, ''].join('/'),
  reservations: [baseUrlV3, 'reservations', ''].join('/'),
  reservationsItem: (token: string) =>
    [baseUrlV3, 'reservations', token, ''].join('/'),
  eventDatesCalendar: [baseUrlV3, 'event-dates-calendar', ''].join('/'),
  eventDates: [baseUrlV3, 'event-dates', ''].join('/'),
  persons: [baseUrlV3, 'persons', ''].join('/'),
  person: (id: number) => [baseUrlV3, 'persons', id, ''].join('/'),
  personBySlug: (slug: string) => [baseUrlV3, 'persons', slug, ''].join('/'),
};

export const V4 = {
  baseUrl: [baseUrlV4, ''].join('/'),
  reservationsItem: (token: string) =>
    [baseUrlV4, 'reservations', token, ''].join('/'),
  events: [baseUrlV4, 'events', ''].join('/'),
};
