import _ from 'lodash';
import qs from 'qs';

const jsonHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

export const checkAllowValue = (value) => {
  if (typeof value === 'undefined') {
    return false;
  }
  if (Array.isArray(value) && value.length === 0) {
    return false;
  }
  return true;
};

const httpRequest = async (
  method,
  url,
  params,
  headers,
  settings = { contentType: 'json', withTokenRequest: false }
) => {
  const options = {
    method,
    credentials: 'omit',
    headers: {
      ...(settings.contentType === 'json' ? { ...jsonHeaders } : {}),
      ...headers,
      ...(process.browser &&
      settings.withTokenRequest &&
      localStorage.getItem('current-user') &&
      JSON.parse(localStorage.getItem('current-user')).token
        ? {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem('current-user')).token
            }`,
          }
        : {}),
    },
  };
  if (params && settings.contentType === 'json') {
    options.body = JSON.stringify(params);
  }
  if (params && settings.contentType === 'formData') {
    const formData = new FormData();
    Object.entries(params).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((item) => {
          formData.append(key, item);
        });
      } else {
        formData.append(key, value);
      }
    });
    options.body = formData;
  }
  const rawResponse = await fetch(url, options);
  const bodyText = (await rawResponse.text()) || JSON.stringify(null);
  const data = JSON.parse(bodyText);
  const { status } = rawResponse;
  return { status, data };
};

export const http = {
  post: async (...arg) => httpRequest('POST', ...arg),
  patch: async (...arg) => httpRequest('PATCH', ...arg),
  delete: async (...arg) => httpRequest('DELETE', ...arg),
  get: async (url, params, headers, option) => {
    const urlWithQuery = new URL(url);
    const values = _.pickBy(params, checkAllowValue);
    urlWithQuery.search = qs.stringify(values, {
      arrayFormat: option?.arrayFormat ? option.arrayFormat : 'repeat',
    });
    const response = await httpRequest(
      'GET',
      urlWithQuery,
      null,
      headers,
      option
    );
    const isError = !response.status || response.status >= 400;
    if (isError) {
      // eslint-disable-next-line @typescript-eslint/no-throw-literal
      throw {
        statusCode: response.status || null,
        data: response.data || response.message,
      };
    }
    return response.data;
  },
};
