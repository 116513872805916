import { Dispatch, ReactNode, SetStateAction } from 'react';
import { CloseSVG } from '../styled/Close';
import {
  initialModalState,
  ModalOptions,
  ModalState,
} from '../../models/modal';
import useOutsideClick from '../../hooks/outside-click';
import { DEVICE_SCREENS_MAX_STRING } from '../../lib/consts';
import { useLoading } from '../../providers/loading';

export function ModalTitleWrapper({
  children,
  setModalState,
  customClose,
  showClose = true,
  ...restProps
}: {
  customClose?: ReactNode;
  children?: ReactNode;
  setModalState: Dispatch<SetStateAction<ModalState>>;
  className?: string;
  showClose?: boolean;
}) {
  return (
    <div
      css={{
        padding: '0 30px',
        height: '70px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
      {...restProps}
    >
      {children}
      {showClose && (
        <div
          css={{
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            padding: '1em',
            margin: '-1em',
            marginLeft: 'auto',
          }}
        >
          {customClose || (
            <CloseSVG onClick={() => setModalState(initialModalState)} />
          )}
        </div>
      )}
    </div>
  );
}

export function ModalTitle({ children }: { children: ReactNode }) {
  return (
    <div
      css={{
        fontSize: '18px',
        lineHeight: '1',
        color: '#333',
        flex: '1 0 0',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        padding: '3px 0',
      }}
    >
      {children}
    </div>
  );
}

export function ModalContent({
  children,
  ...restProps
}: {
  children: ReactNode;
}) {
  return (
    <div
      css={{
        // height: 'calc(100% - 70px)',
        // overflowY: 'auto',
        overflowScrolling: 'touch',
        padding: '0 30px 30px',
      }}
      {...restProps}
    >
      {children}
    </div>
  );
}

export function ModalContentWrapper({ children }: { children: ReactNode }) {
  return (
    <div
      css={{
        position: 'relative',
      }}
    >
      {children}
    </div>
  );
}
export function ModalWindowComponent({
  content,
  setModalState,
  options,
  outsideCloseCallback,
  enableOutsideClick = true,
}: {
  content: ReactNode | string;
  setModalState: Dispatch<SetStateAction<ModalState>>;
  options?: ModalOptions;
  outsideCloseCallback?: () => void;
  enableOutsideClick?: boolean;
}) {
  const outsideRef = useOutsideClick(() => {
    if (enableOutsideClick) {
      if (outsideCloseCallback) {
        outsideCloseCallback();
      }
      setModalState({ show: false, content: '' });
    }
  });
  const { isLoading } = useLoading();
  return (
    <div
      css={{
        position: 'fixed',
        left: '0',
        right: '0',
        top: '0',
        bottom: '0',
        zIndex: 9999,
        background: options?.background ?? 'rgba(0, 0, 0, 0.4)',
        display: isLoading ? 'none' : 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: enableOutsideClick ? 'pointer' : 'default',
      }}
    >
      <div
        css={{
          width: options?.width ?? 520,
          maxWidth: '90%',
          height: 'auto',
          maxHeight: '90vh',
          backgroundColor: '#fff',
          overflowY: 'auto',
          fontFamily: 'Golos',
          borderRadius: '4px',
          cursor: 'default',
          ...(options?.boxShadow && { boxShadow: options.boxShadow }),
          [DEVICE_SCREENS_MAX_STRING.sm]: {
            width: '100%',
            height: '100%',
            maxWidth: '100%',
            minHeight: '100%',
            maxHeight: '100%',
            borderRadius: 0,
          },
        }}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        ref={outsideRef}
      >
        {content}
      </div>
    </div>
  );
}
