import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { http } from '../../lib/api/http';
import { Favorite, FavoriteKinds } from '../../models/favorite';
import { V2, V3, V4 } from '../../lib/api/urls';
import { useAuthorization } from '../authorization';
import {
  FAVORITES_ACTIONS,
  getFavoriteKind,
  UpdateFavoritesSignature,
} from './index.model';
import { Action, Place, Person, Material } from '../../models';
import { PersonInListV3 } from '../../models/person';

const FavoriteContext = createContext<{
  getCount: () => number;
  getFavId: (
    itemId: string | number,
    kind: keyof typeof FavoriteKinds
  ) => string | undefined;
  updateFavorite: UpdateFavoritesSignature;
  getFavoritesList: () => Favorite[];
  getFilteredList: (kind: keyof typeof FavoriteKinds) => {
    [key: number]: Action | Material | Person | Place;
  };
} | null>(null);

const defaultFavList = {
  [FavoriteKinds.event]: {},
  [FavoriteKinds.place]: {},
  [FavoriteKinds.person]: {},
  [FavoriteKinds.material]: {},
};

export function FavoriteProvider({ children }: { children: React.ReactNode }) {
  const authContext = useAuthorization();
  const [favoriteList, setFavoriteList] = useState<{
    [FavoriteKinds.event]: { [key: number]: Action };
    [FavoriteKinds.place]: { [key: number]: Place };
    [FavoriteKinds.person]: { [key: number]: Person };
    [FavoriteKinds.material]: { [key: number]: Material };
  }>(defaultFavList);

  const getFavId = useCallback(
    (itemId: string | number, kind: keyof typeof FavoriteKinds) =>
      Object.keys(favoriteList[kind]).find((key) => {
        switch (kind) {
          case FavoriteKinds.event:
          case FavoriteKinds.place:
            return favoriteList[kind][Number(key)].remote_id === itemId;
          default:
            return favoriteList[kind][Number(key)].id === itemId;
        }
      }),
    [favoriteList]
  );

  const getCount = useCallback(
    () =>
      Object.values(favoriteList)
        .map((kindItem) => Object.values(kindItem))
        .flat().length,
    [favoriteList]
  );

  function updateFavoritesList(
    action: keyof typeof FAVORITES_ACTIONS,
    kind: keyof typeof FavoriteKinds,
    id: number,
    item?: Action | Place | PersonInListV3 | Material
  ) {
    if (action === FAVORITES_ACTIONS.ADD && item) {
      if (kind === 'person') {
        // fix types
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        setFavoriteList((oldState) => ({
          ...oldState,
          [kind]: {
            ...oldState[kind],
            [id]: item,
          },
        }));
      }
      if (kind !== 'person' && 'status' in item && item.status === 'active') {
        setFavoriteList((oldState) => ({
          ...oldState,
          [kind]: {
            ...oldState[kind],
            [id]: item,
          },
        }));
      }
    }
    if (action === FAVORITES_ACTIONS.DELETE) {
      setFavoriteList((oldState) => {
        const newState = { ...oldState };
        delete newState[kind][id];
        return newState;
      });
    }
  }

  async function fetchFavoriteByKind(
    kind: keyof typeof FavoriteKinds,
    favId: number,
    itemId: string | number
  ) {
    // TODO сделать через await?;
    if (itemId) {
      switch (kind) {
        case FavoriteKinds.event:
          await http
            .get(V4.events, { remote_id: itemId, city: null }, null, {
              contentType: 'json',
              withTokenRequest: true,
            })
            .then((event: { results: Action[] }) => {
              if (event.results.length) {
                updateFavoritesList(
                  FAVORITES_ACTIONS.ADD,
                  kind,
                  favId,
                  event.results[0]
                );
              }
            });
          break;
        case FavoriteKinds.person:
          await http
            .get(V3.persons, { id: itemId }, null, {
              contentType: 'json',
              withTokenRequest: true,
            })
            .then((person: { results: PersonInListV3[] }) => {
              if (person.results.length) {
                updateFavoritesList(
                  FAVORITES_ACTIONS.ADD,
                  kind,
                  favId,
                  person.results[0]
                );
              }
            });
          break;
        case FavoriteKinds.place:
          await http
            .get(V2.places, { remote_id: itemId }, null, {
              contentType: 'json',
              withTokenRequest: true,
            })
            .then((place: { results: Place[] }) => {
              if (place.results.length) {
                updateFavoritesList(
                  FAVORITES_ACTIONS.ADD,
                  kind,
                  favId,
                  place.results[0]
                );
              }
            });
          break;
        case FavoriteKinds.material:
          await http
            .get(V2.material(itemId as number), null, null, {
              contentType: 'json',
              withTokenRequest: true,
            })
            .then((material: Material) => {
              if (material) {
                updateFavoritesList(
                  FAVORITES_ACTIONS.ADD,
                  kind,
                  favId,
                  material
                );
              }
            });
          break;
        default:
          break;
      }
    }
  }

  function fetchFavorites() {
    return http
      .get(
        V3.favorites,
        {
          brief: true,
        },
        null,
        { contentType: 'json', withTokenRequest: true }
      )
      .then((response: { results: Favorite[] }) => {
        response.results.forEach(async (favItem) => {
          const kind = getFavoriteKind(favItem);

          if (kind) {
            const favItemId = favItem[kind];
            if (favItemId) {
              await fetchFavoriteByKind(kind, favItem.id, favItemId);
            }
          }
        });
      });
  }

  const updateFavorite: UpdateFavoritesSignature = useCallback(
    (itemId, kind) => {
      const favId = getFavId(itemId, kind);
      if (favId) {
        return http
          .delete(V3.favoriteItem(favId), null, null, {
            contentType: 'json',
            withTokenRequest: true,
          })
          .then((response) => {
            if (response.status === 204) {
              updateFavoritesList(
                FAVORITES_ACTIONS.DELETE,
                kind,
                Number(favId)
              );
            }
          });
      }
      return http
        .post(V3.favorites, { [kind]: itemId }, null, {
          contentType: 'json',
          withTokenRequest: true,
        })
        .then((response: { status: number; data: Favorite }) => {
          if (response.status === 201) {
            fetchFavoriteByKind(
              getFavoriteKind(response.data)!,
              response.data.id,
              itemId
            );
          }
        });
    },
    [getFavId]
  );
  const getFilteredList = useCallback(
    (kind: keyof typeof FavoriteKinds) => favoriteList[kind],
    [favoriteList]
  );

  const getFavoritesList = useCallback(
    () => Object.values(favoriteList),
    [favoriteList]
  );
  useEffect(() => {
    setFavoriteList(defaultFavList);
    if (authContext.getUser()) {
      fetchFavorites();
    }
  }, [authContext.getUser()]);
  const contextValue = useMemo(
    () => ({
      updateFavorite,
      getFavoritesList,
      getFilteredList,
      getCount,
      getFavId,
    }),
    [updateFavorite, getFavoritesList, getFilteredList, getCount, getFavId]
  );
  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <FavoriteContext.Provider value={contextValue}>
      {children}
    </FavoriteContext.Provider>
  );
}

export function useFavorite() {
  return useContext(FavoriteContext);
}
