let API_SERVER_VAL = '';
let MAIN_GB_DOMAIN_VAL = '';

if (process.env.DEPLOY_ENV) {
  switch (process.env.DEPLOY_ENV) {
    case 'dev':
      API_SERVER_VAL = 'https://api.gorbilet.dev.wwhw.org';
      MAIN_GB_DOMAIN_VAL = 'https://gorbilet.dev.wwhw.org';
      break;
    case 'stage':
      API_SERVER_VAL = 'https://api.stage.gorbilet.com';
      MAIN_GB_DOMAIN_VAL = 'https://stage.gorbilet.com';
      break;
    case 'master':
      API_SERVER_VAL = 'https://api.gorbilet.com';
      MAIN_GB_DOMAIN_VAL = 'https://gorbilet.com';
      break;
    default:
      API_SERVER_VAL = 'https://api.gorbilet.dev.wwhw.org';
      MAIN_GB_DOMAIN_VAL = 'https://gorbilet.dev.wwhw.org';
      break;
  }
} else {
  API_SERVER_VAL = 'https://api.gorbilet.dev.wwhw.org';
  MAIN_GB_DOMAIN_VAL = 'https://gorbilet.dev.wwhw.org';
}

export const API_URL = API_SERVER_VAL;

export const BLOG_DOMAIN = MAIN_GB_DOMAIN_VAL;

export const GB_DOMAIN = MAIN_GB_DOMAIN_VAL;

export const TEPLOHOD_SCRIPT_URL =
  'https://api.teplohod.info/v1/widget/widget.js';
