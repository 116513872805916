import styled from '@emotion/styled';
import Close from '../../public/img/close.svg';

export const CloseSVG = styled(Close)({
  width: '20px',
  height: '20px',
  fill: '#e6e6e6',
  backfaceVisibility: 'hidden',
  transition: 'all 0.3s ease',
  '&:hover': { transform: 'rotate(90deg)' },
});
