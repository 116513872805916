export interface Favorite {
  id: number;
  created_at: string;
  updated_at: string;
  remote_id: string | null;
  place: string | null;
  event: string | null;
  restaurant_event: string | null;
  person: number | null;
  material: number | null;
  user: string;
}
export interface IFavorite extends Favorite {
  kind: keyof typeof FavoriteKinds;
}

export const FavoriteKinds: {
  event: 'event';
  place: 'place';
  person: 'person';
  material: 'material';
} = {
  event: 'event',
  place: 'place',
  person: 'person',
  material: 'material',
};
